@font-face {
    font-family: 'Dubai Regular';
    src: local('Dubai-Regular'), url(../../assets/fonts/Dubai_Fonts/Dubai-Regular.ttf) format('truetype');
}

img{
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none;
}
@font-face {
    font-family: 'Alegreya Sans Regular';
    src: local('Alegreya-Sans Regular'), url(../../assets/fonts/Alegreya_Sans/AlegreyaSans-Regular.ttf) format('truetype');
}

.sidemenu-container {
    width: 200px;
    background: #F6F9FC;
    box-shadow: 0px 0px 3px 1px #00000016;
    position: relative;
}

@media (min-width: 0px) and (max-width: 840px) {
    .sidemenu-container {
        width: 140px;
    }

    .sidemenu-button {
        font-size: 12px;
        max-height: 40px;
    }
}

.sidemenu-user-container {
    height: 150px;
    border-bottom: 20px solid #CEE2FF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sidemenu-user {
    color: #BDE0EF;
}

.sidemenu-username {
    font-weight: bold;
    font-family: Dubai Regular;
    height: 10px;
    margin-top: 10px;
}

.sidemenu-role {
    font-size: 0.8em;
    font-family: Alegreya Sans Regular;
}

.sidemenu-button {
    background: white;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 2px 3px 1px #00000016;
    font-family: Dubai Regular;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    margin: 10px 15px;
    height: 100%;
    max-height: 55px;
}

@media (min-width: 0px) and (max-width: 840px) {
    .sidemenu-button {
        font-size: 12px;
        max-height: 40px;
    }
}

.sidemenu-button:hover:not(.disabled) {
    box-shadow: inset 4px 4px 0px #9a62b34d;
}

.sidemenu-button:active:not(.disabled) {
    box-shadow: inset 6px 6px 0px #9a62b34d;
}

.sidemenu-button.disabled {
    background: rgb(221, 221, 221);
    color: #868686;
    cursor: default;
}

.sidemenu-button.selected {
    /*background: rgb(154, 98, 179, .3);*/
    /*box-shadow: inset 4px 4px 0px #9a62b34d;*/
}

/** Users logo in the sidemenu **/
.sidemenu-user img {
    width: 95px;
    max-height: 90px;
}

/** Sidemenu container **/
.sidemenu-buttons-container {
    height: calc(100% - 170px);
    display: flex;
    flex-direction: column;
}

/** Sidemenu button container resizing **/
@media (min-height: 0px) and (max-height: 660px) {
    .sidemenu-button {
        margin: 5px 15px;
    }
}