.login-screen-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-screen {
    z-index: 1;
}

.login-screen-background {
    width: 100%;
    height: 200%;
    background: none;
    transform: rotate(-45deg);
    position: absolute;
}

.login-screen-background-seperator {
    background: #e4f4fb;
    width: 300%;
    height: 100%;
    position: absolute;
    left: 60%;
}

.login-screen {
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
}

.login-screen-left {
    display: flex;
    align-items: center;
    width: 50%;
}

.login-screen-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

.login-screen-left-inner {
    padding-left: 20%;
    text-align: left;
}

.login-screen-title {
    text-align: left;
    font-weight: bold;
}

.login-screen-title-upper {
    font-size: 40px;
}
/* added - RESPONSIVENESS */
.login-screen-title-middle,
.login-screen-title-lower {
    line-height: .8;
}
@media screen and (min-width:1300px){
    .login-screen-title-middle,
    .login-screen-title-lower {
        font-size: 100px;
    }
}
@media (min-width:1080px) and (max-width:1300px){
     .login-screen-title-middle,
    .login-screen-title-lower {
        font-size: 80px;
    }
}
@media (min-width:868px) and (max-width:1080px){
     .login-screen-title-middle,
    .login-screen-title-lower {
        font-size: 70px;
    }
}
@media (min-width:768px) and (max-width:868px){
     .login-screen-title-middle,
    .login-screen-title-lower {
        font-size: 60px;
    }
}
@media screen and (max-width:768px){
    .login-screen-title-middle,
    .login-screen-title-lower {
        font-size: 100px;
    }
    .login-screen-left-inner{
        width:100%;
        padding-top:100px;
    }
    .login-screen{
        overflow-y: auto;
    }
    .login-screen-right{
        min-height: 50%;
    }
    .login-box-container{
        min-height: 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .login-box-container .login-box-userid,
    .login-box-container .login-box-pwd{
        height:fit-content;
    }
}
/* END */
.login-screen-desc {
    max-width: 520px;
    line-height: 30px;
    margin-top: 38px;
}

.login-screen-login-button,
.login-screen-signup-button {
    display: inline-block;
    min-height: 40px;
    font-size: 20px;
    padding-top: 12px;
    background-color: white;
    border-radius: 30px;
    text-align: center;
    cursor: pointer;
}

.login-screen-login-button {
    min-width: 125px;
    margin: 30px 10px 50px 0;
}

.login-screen-signup-button {
    min-width: 125px;
    margin: 30px 0 50px 10px;
}

.login-screen-signup-button a,
.login-screen-signup-button a {
    text-decoration: none;
    color: black;
}

.login-screen-signup-button a:visited,
.login-screen-signup-button a:visited {
    text-decoration: none;
    color: black;
}

/*The login component style*/
.login-wrapper {
    height: 80%;
}

.login-title {
    font-size: 20px;
    padding: 10px 0;
}
.login-box {
    margin: 20px auto;
    position: relative;
    padding-left: 110px;
    padding-right: 110px;
}

img{
    -webkit-user-select: none; /* Chrome/Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none;
}
@media (max-width: 1430px) {
    .login-box {
        padding-left: 50px;
        padding-right: 50px;
    }
}

.login-box-container {
    border: 1px solid #F6F9FC;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #F6F9FC;
    box-shadow: 2px 4px 5px #00000016;
    text-align: left;
    font-size: 15px;
}

.login-box-userid {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.login-box-pwd {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.login-box-pwd-title {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    margin-bottom: 5px;
    flex: 1;
}

.login-box-userid,
.login-box-pwd {
    padding-left: 10%;
    padding-right: 10%;
}
.login-box-userid input {
    font-size: 21px;
    height: 30px;
    width: 100%;
    flex: 2;
}
.login-box-pwd input {
    font-size: 21px;
    height: 30px;
    width: 100%;
    flex: 2;
}

.login-button {
    margin-top: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
    width: 125px;
    margin-left: auto;
    margin-right: auto;
}

.login-button input {
    font-size: 14px;
    -webkit-appearance: button;
    width: 100%;
    padding: 6px 0px;
    border-radius: 30px;
    cursor: pointer;
}

.login-forgot-pwd {
    text-align: center;
    cursor: pointer;
}

.login-heading {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 30px;
}

/*The signup component style*/

.signup-wrapper {
    width: 90%;
    margin: 20px auto 0 auto;
    max-width: 750px;
}

.signup-step-circle-wrapper {
    width: 60px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
}

.signup-step-circle {
    width: 40px;
    height: 40px;
    border-radius: 25px 25px;
    background-color: white;
    display: inline-block;
    vertical-align: middle;
    box-shadow: 0px 3px 10px -4px #888888;
}

.signup-step-circle.selected {
    background: #9a62b3;
}

.signup-step-dash {
    width: 19%;
    max-width: 140px;
    border: 1.5px solid #ababab;
    display: inline-block;
    min-width: 58px;
}

.signup-title {
    font-size: 30px;
    margin: 15px 0;
    font-family: Dubai Bold;
}

.signup-box {
    /*padding: 15px 0;*/
    border: 1px solid #F6F9FC;
    background-color: #F6F9FC;
    box-shadow: 0px 3px 10px -2px rgb(0, 76, 88, .3);
}

.signup-field {
    display: flex;
    width: 100%;
    margin: 10px 0;
    height: 30px;
    position: relative;
    align-items: center;
}

.signup-field:first-child {
    margin-top: 20px;
}

.signup-field:last-child {
    margin-bottom: 20px;
}

.signup-field-title {
    width: 215px;
    margin: 0 20px 0 60px;
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 1em;
    white-space: nowrap;
    font-family: Dubai Regular;
}
.signup-field-text {
    height: 21px;
    width: 80%;
    margin-right: 50px;
    font-size: 1em;
    padding: 5px;
    padding-left: 10px;
    border: 0px;
    box-shadow: 0px 3px 10px -4px rgb(183, 183, 183, .8);
    font-family: Dubai Regular;
}
.signup-box-organisation-name {
    margin-top: 10px;
}
.signup-box-password-confirm {
    margin-bottom: 20px;
}

.signup-next-button input {
    margin: 20px 0;
    width: 120px;
    -webkit-appearance: button;
    height: 30px;
    font-size: 15px;
    border-radius: 20px;
    box-shadow: 0px 3px 5px -4px #888888;
    border: 0px;
    cursor: pointer;
    background-color: #9a62b3;
    color: white;
    font-family: Dubai Regular;
    margin-right: 20px;
    margin-left: 20px;
}

.signup-stage-three input[type=checkbox] {
    margin-right: 20px;
}

.signup-stage-three a {
    color: black;
}

.signup-stage-three .payment-overview {
    margin-top: 20px;
}

.signup-stage-three .col {
    display: flex;
    flex-direction: column;
    margin: 20px 0px 20px 60px;
    text-align: left;
    position: relative;
}

.signup-stage-three .col:last-child {
    flex: 1;
    margin-right: 150px;
}

.signup-stage-three .col span, .signup-stage-three .col div {
    margin: 5px 0px;
    font-family: Dubai Regular;
}

.signup-stage-three .col div {
    font-weight: bold;
    display: flex;
    margin: 0px;
}

.signup-stage-three .col .plus-symbol {
    margin-left: auto;
}

.signup-stage-three .signup-box-container .col .row .before-price {
    position: relative;
}

.signup-stage-three .signup-box-container .col .row .before-price::after {
    border-bottom: 0.125em solid #50b383;
    content: "";
    left: -2px;
    margin-top: calc(0.125em / 2 * -1);
    position: absolute;
    right: 0;
    top: 50%;
    width: calc(100% + 5px);
  }

.signup-stage-three .signup-box-container .col .row .discounted-cost {
    color: #50b383;
    margin-left: 20px;
}

.signup-stage-three .final-cost {
    font-size: 30px;
}

.signup-stage-three span.final-label {
    height: 51px;
    display: flex;
    align-items: center;
}


.signup-stage-three .payment-overview .signup-box-container {
    display: flex;
}

.signup-next-button input.disabled {
    background-color: #b9b9b9;
}

@media only screen and (max-width: 768px) {
    .login-screen-login-button,
    .login-screen-signup-button {
        display: block;
    }
}

@media only screen and (max-width: 768px) {
    .login-screen {
        display: block;
    }

    .login-screen-left,
    .login-screen-right {
        width: 100%;
    }

    .login-screen-left-inner {
        padding-left: 10%;
        padding-right: 10%;
    }

    .login-screen-login-button,
    .login-screen-signup-button {
        margin-left: 0;
        margin-right: 0;
    }

    .login-wrapper {
        padding-top: 10%;
        padding-bottom: 10%;
    }

    .login-title {
        font-size: 30px;
    }

    .login-box-userid, .login-box-pwd {
        padding-left: 10%;
        padding-right: 10%;
        font-size: 21px;
        height: 30px;
    }
}

.login-box-userid-title {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    margin-bottom: 5px;
    flex: 1;
}

@media only screen and (max-width: 375px) {
    .login-screen-title-upper {
        font-size: 30px;
    }
    .login-screen-title-middle,
    .login-screen-title-lower {
        line-height: .8;
        font-size: 60px;
    }

    .login-box-userid, .login-box-pwd {
        padding-left: 5%;
        padding-right: 5%;
    }

    .login-box-userid input {
        margin-left: 25px;
    }

    .login-box-pwd input {
        margin-left: 10px;
    }

    .signup-field-title {
        width: 130px;
        font-size: 0.7em;
    }

    .signup-wrapper {
        width: 90%;
    }
}

.signup-stage-two .signup-box-container {
    display: flex;
    font-family: Dubai Regular;
}

.signup-stage-two .signup-box-container .signup-box.selected {
    border: 5px solid #5a5380;
    top: -15px;
}

.signup-stage-two .signup-box-container .signup-box {
    position: relative;
    flex: 1 1;
    margin: 0px 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 360px;
    display: flex;
    flex-direction: column;
    top: 0px;
    transition: top .15s ease-in-out;
    cursor: pointer;
}

.signup-stage-two .signup-box-container .signup-box:hover {
    top: -15px;
}

.signup-stage-two .descriptive-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
}

.signup-stage-two .descriptive-container .row {
    display: flex;
    margin-bottom: 15px;
}

.signup-stage-two .descriptive-container .row span {
    text-align: left;
}

.signup-stage-two i.fa-star {
    font-size: 25px;
    padding-right: 10px;
    color: #9a62b3;
}

.signup-stage-two .cost-container {
    font-family: Alegreya Sans Black;
    font-size: 55px;
    color: #5a5380;
    margin-top: auto;
}

.signup-stage-two .level-text {
    font-size: 25px;
}

.signup-stage-two .signup-box-container .signup-box:first-child {
    margin-left: 0px;
}

.signup-stage-two .signup-box-container .signup-box:last-child {
    margin-right: 0px;
}

@media only screen and (min-width: 1440px) {
    .signup-field-text {
        width: 80%;
    }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .signup-field-text {
        width: 80%;
    }
}

@media only screen and (max-width: 500px) {
    .signup-field-title {
        width: 150px;
        margin: 0 0 0 20px;
    }
}

.signup-field.signup-box-organisation-type .MuiInput-root {
    width: calc(80% + 20px);
    margin-right: 50px;
    background: white;
}

.signup-field.signup-box-organisation-type .MuiInput-underline:before, .MuiInput-underline:after {
    opacity: 0;
}

.signup-field.signup-box-organisation-type .MuiSelect-select.MuiSelect-selectMenu {
    padding: 0px;
    height: 32px;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    padding-left: 10px;
    box-shadow: 0px 3px 10px -4px rgb(183, 183, 183, .8);
}

.signup-field.signup-box-organisation-type .MuiSelect-icon {
    height: 32px;
    width: 32px;
    background: rgba(0, 0, 0, 0.54);
    top: 0;
    color: white;
}

.password-strength-levels {
    display: flex;
    align-items: center;
    width: calc(80% + 20px);
    margin-right: 50px;
}

.password-strength-levels div {
    width: 60px;
    height: 8px;
    margin-right: 10px;
}

.password-strength-levels div.level-poor {
    border: 2px solid #fa344c;
}

.password-strength-levels div.level-weak {
    border: 2px solid #fa344c;
}

.password-strength-levels div.level-average {
    border: 2px solid #fa9034;
}

.password-strength-levels div.level-excellent {
    border: 2px solid #50B383;
}

.password-strength-levels div.level-poor:not(.empty) {
    background-color: #fa344c;
}

.password-strength-levels div.level-weak:not(.empty) {
    background-color: #fa344c;
}

.password-strength-levels div.level-average:not(.empty) {
    background-color: #fa9034;
}

.password-strength-levels div:not(.empty) {
    background-color: #50B383;
}

.password-level-span {
    color: #50B383;
    font-family: Alegreya Sans Black;
}

.password-level-span.level-poor {
    color: #fa344c;
    font-family: Alegreya Sans Black;
}

.password-level-span.level-weak {
    color: #fa344c;
    font-family: Alegreya Sans Black;
}

.password-level-span.level-average {
    color: #fa9034;
    font-family: Alegreya Sans Black;
}

.password-level-span.level-excellent {
    color: #50B383;
    font-family: Alegreya Sans Black;
}

/* Signup - Stage 2 */
.signup-stage-two .section-component {
    display: flex;
    box-sizing: border-box;
    padding: 10px 50px;
}

.signup-stage-two .section-component:first-child {
    padding-top: 30px;
}

.signup-stage-two .section-component:last-child {
    padding-bottom: 30px;
}

.signup-stage-two .section-component .col-1 {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    font-family: Dubai Bold;
    width: 100%;
}

.signup-stage-two .section-component .col-1 .descriptive-text {
    font-family: Dubai Regular;
    line-height: 1;
    font-size: 14px;
}

.signup-stage-two .section-component .col-2 {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    font-family: Dubai Bold;
    padding-top: 5px;
    align-items: flex-end;
}

.signup-stage-two .col-1 input[type='range'] {
    width: calc(100% - 30px);
    -webkit-appearance: none;
    height: 11px;
    background: #ffffff;
    outline: none;
    opacity: 0.7;
    transition: opacity .2s;
    cursor: pointer;
    border: 1px solid grey;
    border-radius: 20px;
    margin-top: 10px;
    background: linear-gradient(to right, #82CFD0 0%, #82CFD0 2%, #fff 2%, #fff 100%);
}

.signup-stage-two .col-1 input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #9a62b3;
    border-radius: 50px;
    cursor: pointer;
  }

  .signup-stage-two .col-1 input[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #9a62b3;
    border-radius: 50px;
    cursor: pointer;
}

  
.signup-stage-two .price-for-tests {
    color: #50B383;
    position: absolute;
    top: 29px;
}

.signup-stage-two .slider-minimum-maximum-container {
    display: flex;
    width: calc(100% - 30px);
}

.signup-stage-two .slider-minimum-maximum-container span {
    font-family: Dubai Regular;
}

.signup-stage-two .slider-minimum-maximum-container .low {
    margin-right: auto;
}

.signup-stage-two .slider-minimum-maximum-container .high {
    margin-left: auto;
}

.signup-stage-two .summary-section {
    display: flex;
}

.signup-stage-two .summary-section span {
    font-family: Dubai Regular;
}

.signup-stage-two .summary-section .col-1 {
    margin-right: 60px;
}

.signup-stage-two .summary-section .col-2 {
    padding-top: 0px;
    align-items: baseline;
}

.signup-stage-two .summary-section .col-2 span {
    font-family: Dubai Bold;
}

.signup-stage-two .summary-section .old-price {
    padding-right: 15px;
}

.signup-stage-two .summary-section .new-price {
    color: #50B383;
}

.signup-stage-two .summary-section .save-percent {
    color: #50B383;
}

.signup-stage-two .additional-items-section .col-1 {
    position: relative;
    width: 25px;
    padding-top: 3px;
    padding-right: 15px;
}

.signup-stage-two .additional-items-section .col-2 {
    align-items: baseline;
    padding-top: 0px;
}

.signup-stage-two .additional-items-section .col-3 {
    font-family: Dubai Bold;
}

.signup-stage-two .additional-items-section .col-2 .descriptive-text {
    font-family: Dubai Regular;
    text-align: left;
    font-size: 14px;
    line-height: 1.2;
}

.signup-stage-two .example-button {
    margin: 15px 0;
    width: 120px;
    -webkit-appearance: button;
    height: 30px;
    font-size: 15px;
    border-radius: 20px;
    box-shadow: 0px 3px 5px -4px #888888;
    border: 0px;
    cursor: pointer;
    background-color: white;
    font-family: Dubai Regular;
}

.signup-stage-three input[type=checkbox] {
    position: relative;
    cursor: pointer;
    margin-bottom: 40px;
}

.signup-stage-three label {
    cursor: pointer;
    font-family: Dubai Regular;
}

.signup-stage-three input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    border: 2px solid #555555;
    border-radius: 3px;
    background-color: white;
    cursor: pointer;
}

.signup-stage-three input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background-color: #9a62b3;
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 1px;
    cursor: pointer;
}

.signup-stage-three .card-section {
    display: flex;
}

.signup-stage-three .card-section .col-1 {
    display: flex;
    flex-direction: column;
}

.signup-stage-three .card-section .col-1 span {
    width: 215px;
    margin: 0 20px 0 60px;
    display: flex;
    align-items: center;
    text-align: left;
    font-size: 1em;
    white-space: nowrap;
    font-family: Dubai Regular;
}

.signup-field.signup-box-card-type .MuiInput-root {
    width: calc(80% + 20px);
    margin-right: 50px;
    background: white;
}

.signup-field.signup-box-card-type .MuiInput-underline:before, .MuiInput-underline:after {
    opacity: 0;
}

.signup-field.signup-box-card-type .MuiSelect-select.MuiSelect-selectMenu {
    padding: 0px;
    height: 32px;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    padding-left: 10px;
    box-shadow: 0px 3px 10px -4px rgb(183, 183, 183, .8);
}

.signup-field.signup-box-card-type .MuiSelect-icon {
    height: 32px;
    width: 32px;
    background: rgba(0, 0, 0, 0.54);
    top: 0;
    color: white;
}

.school-name {
    font-family: Dubai Bold;
    font-size: 20px;
    margin-top: 30px;
    position: relative;
    display: flex;
}

.new-password-login {
    display: flex;
    flex-direction: column;
}

.new-password-login, .new-password-login input {
    font-size: 21px;
}

.new-password-login .section {
    display: flex;
    flex-direction: column;
    padding: 0% 10%;
    margin: 5px 0px;
}