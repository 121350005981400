.signup-success .signup-box-container {
    padding: 30px 0px;
}

.signup-success .signup-box-container span {
    font-size: 22px;
}

.signup-success input {
    margin: 20px 0;
    width: 120px;
    -webkit-appearance: button;
    height: 30px;
    font-size: 15px;
    border-radius: 20px;
    box-shadow: 0 3px 5px -4px #888;
    border: 0;
    cursor: pointer;
    background-color: white;
    color: black;
    font-family: Dubai Regular;
}