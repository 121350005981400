@font-face {
  font-family: 'Alegreya Sans Regular';
  src: local('AlegreyaSans-Regular'), url(../assets/fonts/Alegreya_Sans/AlegreyaSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Alegreya Sans ExtraBold';
  src: local('AlegreyaSans-ExtraBold'), url(../assets/fonts/Alegreya_Sans/AlegreyaSans-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Alegreya Sans Black';
  src: local('AlegreyaSans-Black'), url(../assets/fonts/Alegreya_Sans/AlegreyaSans-Black.ttf) format('truetype');
}

.header-container {
    z-index: 1;
    position: relative;
    background: #5A5380;
    height: 30px;
    display: flex;
    align-items: center;
    color: white;
    box-shadow: 0px -6px 17px 6px #000000d6;
}

.header-menu {
    position: absolute;
    right: 0;
    padding: 5px 10px;
    margin-right: 10px;
}

.logo {
    width: 200px;
    font-weight: bold;
    font-family: Alegreya Sans Black;
    font-size: 19px;
}

.download-button {
    padding: 10px;
    margin: 10px;
    border-radius: 20px;
    border: none;
    background: white;
    box-shadow: 1px 1px 3px 1px #00000016;
    cursor: pointer;
}

.download-button-side {
  padding: 4px;
  margin: 6px;
  border-radius: 20px;
  border: none;
  height: 20px;
  background: white;
  box-shadow: 1px 1px 3px 1px #00000016;
  white-space: nowrap;
}


.field-info {
    position: absolute;
    width: calc(100% - 40px);
    left: 0;
    z-index: 2;
    background: white;
    padding: 10px;
    box-shadow: 1px 1px 3px 1px #00000016;
    text-align: left;
    font-size: 0.9em;
    top: 25px;
}

.required-asterisk {
  /*color: #9a62b3;*/
  margin-left: 3px;
}

.myDetails-field-slider {
  -webkit-appearance: none;
  width: 557px;
  height: 7px;
  background: #ffffff;
  outline: none;
  opacity: 0.7;
  transition: opacity .2s;
  cursor: pointer;
  border: 1px solid grey;
  border-radius: 20px;
}

.myDetails-field-slider.disabled {
  opacity: .3;
}

.myDetails-field-slider.disabled::-webkit-slider-thumb {
  background: grey;
}

.parent-slider .myDetails-field-slider {
  width: 440px;
}

.speed-controls {
  height: 50px;
}

.speed-controls .myDetails-field-slider {
  width: 479px;
}

.speed-controls .myDetails-field-slider.distance-control {
  width: 462px;
}

.speed-controls .myDetails-field-slider.time-control {
  width: 462px;
}

@media (max-width: 1050px) {
  .speed-controls .myDetails-field-slider.distance-control, .speed-controls .myDetails-field-slider.time-control {
    width: 380px;
  }
}

@media (max-width: 970px) {
  .speed-controls .myDetails-field-slider.distance-control, .speed-controls .myDetails-field-slider.time-control {
    width: 340px;
  }
}

@media (max-width: 920px) {
  .speed-controls .myDetails-field-slider.distance-control, .speed-controls .myDetails-field-slider.time-control {
    width: 290px;
  }
}

@media (max-width: 880px) {
  .speed-controls .myDetails-field-slider.distance-control, .speed-controls .myDetails-field-slider.time-control {
    width: 250px;
  }
}

@media (max-width: 780px) {
  .speed-controls .myDetails-field-slider.distance-control, .speed-controls .myDetails-field-slider.time-control {
    width: 190px;
  }
}

.distance-label {
  width: 85px;
  display: inline-block;
  text-align: left;
}

.time-label {
  width: 85px;
  display: inline-block;
  text-align: left;
}

.myDetails-field-slider:hover {
  opacity: 1;
}

.myDetails-field-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: var(--value);
  border-radius: 50px;
  cursor: pointer;
}

.myDetails-field-slider::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #9a62b3;
    border-radius: 50px;
    cursor: pointer;
}

.gender-section, .parent-height-dontknow {
  display: flex;
}

.gender-section .gender-selection, .parent-height-dontknow .parent-height-dontknow-label {
  cursor: pointer;
  margin-right: 180px;
}

.gender-section:last-child .gender-selection, .parent-height-dontknow:last-child .parent-height-dontknow-label {
  font-family: Dubai Regular;
  margin-right: 0px;
  white-space: nowrap;
}

.gender-section input, .parent-height-dontknow input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.gender-section .checkmark, .parent-height-dontknow .checkmark {
  background: #F6F9FC;
  height: 18px;
  width: 18px;
  display: inline-block;
  border-radius: 50%;
  border: 3px solid #9a62b3;
  cursor: pointer;
  margin-right: 15px;
}

.gender-section .checkmark:hover, .parent-height-dontknow .checkmark:hover {
  /*background-color: #9a62b3;*/
  border: 3px solid #9a62b3;
  transition: border .2s linear;
  transition: background-color .2s linear;
}

.gender-section input:checked ~ .checkmark, .parent-height-dontknow input:checked ~ .checkmark {
  background: #9a62b3;
}

.gender-selection-container {
  display: flex;
  margin-left: 2px;
}

.test-date-field {
  margin: 20px 20px;
}



/** Date Fields **/
.test-date-field .MuiSelect-select, .dob-field .MuiSelect-select, .myDetails-field.drop-down-field .MuiNativeSelect-select {
  min-width: 103px;
}

.test-date-field .MuiSelect-select.MuiSelect-select, .dob-field .MuiSelect-select.MuiSelect-select{
  border: 1px solid #d9d9d9;
}

/* tablet veiw for B2C user -- My Details */
@media (min-width:769px) and (max-width:1040px){
  .dob-field.day_included .MuiSelect-select {
    min-width: 60px;
  }
   .myDetails-field.day_included .MuiSelect-select.MuiSelect-select{
    padding-left: 10px;
  }
  .myDetails-field.day_included .MuiSelect-select.MuiSelect-select span{
    font-size: 90%;
  }
}
@media screen and (max-width:768px){
  .dob-field.day_included .MuiSelect-select {
    min-width: 50px;
  }
   .myDetails-field.day_included .MuiSelect-select.MuiSelect-select{
    padding-left: 10px;
  }
  .myDetails-field.day_included .MuiSelect-select.MuiSelect-select span{
    font-size: 85%;
  }
}
/* tablet veiw for B2C user -- My Details */

.test-date-field .MuiSelect-icon, .dob-field .MuiSelect-icon {
  top: 1px;
  color: white;
  right: 1px;
  position: absolute;
  pointer-events: none;
  background: rgb(29, 179, 12);
  height: calc(100% - 2px);
  width: auto;
}

.test-date-field .test-dropdown-separator, .dob-field .birth-dropdown-separator {
  margin: 0px 10px;
  font-size: 22px;
}

.myDetails-field .MuiFilledInput-inputMarginDense {
  padding-top: 8px;
  padding-bottom: 8px;
}

.myDetails-field .MuiTextField-root {
  width: 500px;
}

.myDetails-field .MuiFormControl-root {
  border-left: 1px solid rgba(0, 0, 0, 0.42);
  border-right: 1px solid rgba(0, 0, 0, 0.42);
  border-top: 1px solid rgba(0, 0, 0, 0.42);
}

.student-id-row .MuiFilledInput-root {
  background: white;
}

.test-date-container {
  display: flex;
  padding-right: 60px;
}

.test-date-field .MuiButton-label{
  text-transform: none;
}

.myDetails-screen-section-icon {
  display: flex;
}

.label-uom {
  margin-left: 5px;
}

/** UOM for the tests the user takes place in (i.e. Distance will have 'm' as the UOM) **/
.test-uom, .label-uom {
  font-family: Dubai Regular;
  color: grey;
}

/** For labels located within the 'Start New Test' screens **/
.test-label-span {
  font-family: Dubai Regular;
  margin-right: 10px;
}

.minutesSecondsLabel {
  margin-left: 30px;
}

.validation-message {
  font-size: 13px;
  font-family: Dubai Regular;
  color: #9a62b3;
  margin-left: 10px;
}

.reports-container {
  display: flex;
  width: 100%;
}

.scroll-bar-external-container {
  width: calc(100% - 210px);
  border-top: 0px;
  border-bottom: 0px;
  z-index: 3000;
  position: absolute;
  height: 100%;
}

.scroll-bar-external-container .left-button {
  height: 18px;
  width: 18px;
  position: absolute;
  left: 9px;
  bottom: -1px;
  font-family: monospace;
  font-size: 16px;
  background-color: #d5d5d5;
  line-height: 12px;
  border: 2px solid transparent;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 0px 1px 3px 1px #00000016;
}

.scroll-bar-external-container .right-button {
  height: 18px;
  width: 18px;
  position: absolute;
  font-family: monospace;
  right: 9px;
  bottom: -1px;
  font-size: 16px;
  line-height: 12px;
  background-color: #d5d5d5;
  border: 2px solid transparent;
  border-radius: 2px;
  cursor: pointer;
  box-shadow: 1px 1px 3px 1px #00000016;
}

.scroll-bar-external-container .scroll-bar-external div {
  /* width: 1118px; */
  width: 1730px;
  /*overflow-x: scroll;*/
  height: 1px;
}

.scroll-bar-external::-webkit-scrollbar:horizontal {
  background: white;
  border-radius: 0px;
  box-shadow: 3px 2px 3px 1px #00000016;
}

.scroll-bar-external-container .scroll-bar-external {
  /*overflow: scroll;*/
  overflow-x: scroll;
  margin-left: 30px;
  margin-right: 18px;
  height: 100%;
}

.scroll-bar-external-container .right-button::after {
  content: '>';
  left: 3px;
  position: absolute;
  top: 1px;
  font-weight: bold;
  color: white;
}

.scroll-bar-external-container .left-button::after {
  content: '<';
  left: 2px;
  position: absolute;
  top: 0px;
  font-weight: bold;
  color: white;
}

.cls-1{fill:#fff;}.cls-2{fill:#000;}

.verified-star {
  height: 21px;
  position: absolute;
  z-index: 23;
  left: -30px;
  color: #50B383;
}

.test-label-span.distance-label {
  white-space: nowrap;
}

.myDetails-field-slider-value.feet-inches-control {
  width: 45px;
}

.norm-selector-container {
  display: flex;
  align-items: center;
}

.norm-selector-container .norm-selector {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}