@font-face {
  font-family: "Dubai Regular";
  src: local("Dubai-Regular"),
    url(../../../assets/fonts/Dubai_Fonts/Dubai-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Dubai Bold";
  src: local("Dubai-Bold"),
    url(../../../assets/fonts/Dubai_Fonts/Dubai-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Alegreya Sans Black";
  src: local("Alegreya Sans Black"),
    url(../../../assets/fonts/Alegreya_Sans/AlegreyaSans-Black.ttf)
      format("truetype");
}
.myDetails-screen-section-icon {
  margin: 0px 20px;
  font-size: 1.5em;
  color: #9d0b00;
  width: 30px;
}

.myDetails-screen-section-header {
  display: flex;
  position: relative;
  height: 50px;
  align-items: center;
  background: white;
  box-shadow: 1px 1px 3px 1px lightgrey;
  cursor: pointer;
}

.myDetails-screen-section-arrow {
  position: absolute;
  right: 0;
  font-size: 1.5em;
  margin-right: 20px;
}

.myDetails-screen-section-title {
  font-weight: bold;
  font-size: 1.5em;
}

.my-details-header-icons {
  margin-top: 5px;
}
.my-details-header-icons svg {
  width: 45px;
}

.myDetails-fields {
  width: 100%;
  height: 0;
  overflow: hidden;
}

.myDetails-fields.expanded.test-date-section {
  padding-top: 0px;
}

.myDetails-fields.expanded {
  height: auto;
  padding: 10px 0;
  overflow: visible;
}

.myDetails-field {
  display: flex;
  width: 100%;
  margin: 10px 0;
  height: 30px;
  position: relative;
  align-items: center;
}

.myDetails-field-title {
  max-width: 240px;
  min-width: 158px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 0.9em;
  font-family: Dubai Regular;
}

.aerobic-fitness-fields .myDetails-field-title {
  width: 400px;
}

.speed-and-agility-fields .myDetails-field-title {
  width: 290px;
}

.myDetails-field-info {
  transform: translate(0px, -7px);
  font-size: 1.2em;
  padding: 5px;
  /*color: #9a62b3;*/
  z-index: 1;
}

.myDetails-info-pointer {
  height: 20px;
  width: 20px;
  background: white;
  box-shadow: 1px 1px 3px 1px lightgrey;
  transform: rotate(45deg) translate(5px, 10px);
  position: absolute;
}

.myDetails-field-text {
  padding: 5px;
}

.myDetails-field-text.walk-run-minutes {
  width: 40px;
}
.myDetails-field-text.walk-run-seconds {
  width: 40px;
}

.myDetails-field-slider-value {
  margin-left: 10px;
  width: 55px;
  border: 0;
  background: none;
}

.grip-strength-avarage span {
  font-size: 24px;
  font-weight: bold;
  font-family: Alegreya Sans Black;
  /*color: #9a62b3;*/
}

.walk-run-colon {
  font-size: 18px;
  padding: 0px 3px;
  font-weight: bold;
  position: relative;
  display: inline-block;
}

.submitTestButton {
  background-color: rgba(29, 179, 12);
  border-radius: 30px;
  padding-left: 25px;
  padding-right: 25px;
}

.screen-section.test-section {
  margin-top: auto;
}

.screen-section,
.submit-button-container {
  width: calc(80% + 30px);
  max-width: 980px;
}

.no-previous-tests {
  position: relative;
  padding: 54px 0px 100px 0px;
}

.no-previous-tests span {
  font-size: 29px;
  /*color: rgb(154, 98, 179, 0.5);*/
  display: inline-block;
  left: -10px;
  position: relative;
  font-family: Dubai Regular;
  font-weight: bold;
}

.previous-test-results-heading {
  display: flex;
  font-size: 21px;
  font-weight: bold;
  margin-left: 20px;
  padding-top: 15px;
  text-align: left;
}

#root .MuiInputBase-input,
#root .MuiButton-label {
  font-family: Dubai Regular;
}

.body-size-and-shape-button-container .MuiInputBase-input {
  font-size: 11px;
  top: -2px;
  position: relative;
}

.body-size-and-shape-button-container
  .MuiButton-iconSizeMedium
  > *:first-child {
  font-size: 17px;
}

.gender-selection {
  font-family: Dubai Regular;
}

@media (max-width: 1200px) {
  .screen-section,
  .submit-button-container {
    width: 95%;
  }
}

@media (max-width: 1040px) {
  .myDetails-fields .myDetails-field .MuiTextField-root {
    width: 400px;
  }
}

@media (max-width: 1120px) {
  .screen .parent-slider .myDetails-field-slider {
    width: 300px;
  }

  .myDetails-field.parent-slider .parent-height-dontknow {
    margin-left: initial;
  }
}

@media (max-width: 1050px) {
  .screen .parent-slider .myDetails-field-slider {
    width: 200px;
  }
}

@media (max-width: 950px) {
  .myDetails-fields .myDetails-field .MuiTextField-root {
    width: 300px;
  }

  .gender-selection-container label.gender-selection {
    margin-right: 100px;
  }
}
.parent-height-dontknow{
  padding-right:10px;
}
.no-of-years {
  color: #9a62b3;
  font-size: 26px;
  font-family: Alegreya Sans Black;
  margin-left: 25px;
}
@media screen and (max-width:850px){
  .myDetails-field .no-of-years {
    margin-left: 10px;
    font-size: 20px;
    text-align: left;
  }
}

.test-navigation-bar {
  display: flex;
  margin-top: auto;
  width: 77%;
  max-width: 945px;
  justify-content: space-between;
  align-items: center;
}

/** Test Button Container **/
.test-navigation-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
}

/** Test Buttons **/
.test-navigation-buttons div img,
.test-navigation-buttons div .test-navigation-buttons-icons {
  width: 150px;
  height: 150px;
  cursor: pointer;
  margin: 15px;
  border-radius: 50%;
  box-shadow: 0px 0px 12px #c9c9c9;
}
@media (min-width:1080px) and (max-width:1300px){
  .test-navigation-buttons div img,
  .test-navigation-buttons div .test-navigation-buttons-icons {
    width: 120px;
    height: 120px
  } 
}
@media (min-width:870px) and (max-width:1080px){
  .test-navigation-buttons div img,
  .test-navigation-buttons div .test-navigation-buttons-icons {
    width: 100px;
    height: 100px
  } 
}
@media (min-width:768px) and (max-width:870px){
  .test-navigation-buttons div img,
  .test-navigation-buttons div .test-navigation-buttons-icons {
    width: 80px;
    height: 80px
  } 
}
@media (max-width:768px){
  .test-navigation-buttons{
    flex-wrap: wrap;
    justify-content: center;
  }
  .test-navigation-buttons div img,
  .test-navigation-buttons div .test-navigation-buttons-icons {
    width: 100px;
    height: 100px;
  } 
  .test-navigation-buttons > div{
    min-width:33.3%;
    display: flex;
    justify-content: center;
  }
}

.test-navigation-buttons div img.selected.filled-out {
  width: 160px;
  height: 160px;
}

@media (max-height: 680px) {
  .body-size-navigation {
    margin-top: 5px;
  }
}

.submit-row .MuiButton-text {
  padding: 9px 25px;
  border-radius: 30px;
}

.test-reports-body {
  margin-left: 3%;
  width: 100%;
  padding: 3% 0;
  max-width: 590px
}

.test-reports-heading {
  display: flex;
}

.test-reports-body-content {
  position: relative;
}

.test-reports-body-content.selected {
  border: 3px solid rgb(154, 98, 179);
  background: rgb(154, 98, 179, .5);
  border-radius: 10px;
}

.test-reports-body-content .MuiCircularProgress-colorPrimary {
  position: absolute;
  width: 30px;
  height: 30px;
  right: -45px;
  color: #9a62b3;
}

.test-reports-body span {
  display: inline-block;
  height: 23px;
  width: 18%;
  white-space: nowrap;
}
.test-reports-body-content span {
  display: inline-block;
  background-color: white;
  border: 1px solid;
  width: 16%;
  font-size: 14px;
}
.more-button {
  float: right;
  width: 14%;
  height: 30px;
  margin: 0% 4%;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 2px 5px #444444;
  background: #9a62b3;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.test-reports-body-content button {
  width: 16%;
  height: 23px;
  margin: 1% 2%;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 2px 5px #444444;
  padding: 0.5% 2% 0%;
  background: white;
  cursor: pointer;
  font-family: Dubai Regular;
}
.test-reports-body span.test-num {
  margin: 1% 2%;
  width: 14%;
}
.test-reports-body span.test-date {
  margin: 1% 2%;
  width: 20%;
}
.test-reports-body span.actual-age {
  margin: 1% 2%;
  width: 14%;
}
.test-reports-body span.fit-age {
  margin: 1% 2%;
  width: 14%;
}

.sort-by-container {
  display: flex;
  width: 90%;
  max-width: 370px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.sort-by-section {
  width: 100px;
}

.sort-by-section span {
  font-weight: bold;
}

.sort-by-radiobuttons label {
  cursor: pointer;
}

.sort-by-radiobuttons div {
  display: flex;
  align-items: center;
}

.sort-by-radiobuttons div input {
  opacity: 0;
  cursor: pointer;
}

.sort-by-radio-visible {
  position: absolute;
  border: 3px solid #9a62b3;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  cursor: pointer;
}

.sort-by-radio-visible.selected {
  background-color: #9a62b3;
}

.view-button {
  cursor: pointer;
}

.class-info-container {
  display: flex;
  align-items: center;
}

.class-info-inputs {
  display: flex;
  flex-direction: column;
}

.parent-height-dontknow {
  margin-left: auto;
  margin-right: auto;
}

.class-info-container button {
  width: 94px;
  height: 26px;
  margin: 1% 2%;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 2px 5px #444444;
  background: white;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.past-test-results-more-btn {
  width: 94px;
  height: 26px;
  margin: 1% 2%;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 2px 5px #444444;
  background: #9a62b3;
  margin-left: auto;
  margin-right: 50px;
  cursor: pointer;
  color: white;
  margin-left: auto;
  margin-right: 50px;
}

.no-previous-tests span {
  font-family: Alegreya Sans Black;
}

.test-reports-heading span {
  font-size: 0.9em;
}

.start-test-button {
  width: 94px;
  height: 26px;
  margin: 1% 2%;
  border: none;
  border-radius: 20px;
  box-shadow: 0px 2px 5px #444444;
  background: #9a62b3;
  margin-left: auto;
  margin-right: 50px;
  cursor: pointer;
  color: white;
  margin-left: auto;
  margin-right: 50px;
}

img{
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none;
}
.dob-checker .MuiCardContent-root .no-of-years {
  display:none;
}
.dob-checker .MuiCardContent-root .myDetails-field-title {
  display:none;
}
.dob-checker .MuiCardContent-root .birth-dropdown-separator{
  visibility: hidden;
  width:50px;
}
.MuiCardContent-root .dob-field, .MuiCardContent-root .card-body-text-span{
  text-align: center;
  align-content: center;
  justify-content: center;
}


@media (min-width: 0px) and (max-width: 990px) {
  .aerobic-fitness-fields .myDetails-field.drop-down-field .MuiNativeSelect-select {
    min-width: 65px;
  }

  .aerobic-fitness-fields .myDetails-field-title {
    width: 100px;
  }
}