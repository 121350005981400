.myFitnessAge-graph-container {
    /* max-width: 500px; */
    margin: 30px;
}

.myFitnessAge-graph {
    height: 500px;
    position: relative;
    background: white;
}

.myFitnessAge-results-line {
    margin: 10px;
    font-size: 1.2em;
}

.myFitnessAge-results-line span {
    font-weight: bold;
}

.myFitnessAge-compare {
    display: flex;
    align-items: center;
    max-width: 500px;
}

.myFitnessAge-compare-button {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    border: 2px solid #9962B1;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.myFitnessAge-button-inner {
    height: 80%;
    width: 80%;
    background: #9962B1;
    border-radius: 50px;
}

.myFitnessAge-compare-text {
    cursor: pointer;
}

.myFitnessAge-graph-and-results-container {
    display: flex;
    width: 100%;    
}

.myFitnessAge-graph-results {
    justify-content: center;
    display: flex;
    flex-direction: column;
}
img{
    -webkit-user-select: none; /* Chrome/Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none;
}