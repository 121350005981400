@font-face {
    font-family: 'Alegreya Sans Black';
    src: local('Alegreya Sans Black'), url(../../assets/fonts/Alegreya_Sans/AlegreyaSans-Black.ttf) format('truetype');
  }

  img{
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none;
}
.screen-container {
    width: calc(100% - 200px);
}

@media (min-width: 0px) and (max-width: 841px) {
    .screen-container {
        width: calc(100% - 140px);
    }
}

.screen-header {
    height: 60px;
    background: #778599;
    color: white;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
}

.screen-title {
    margin-left: 20px;
    font-size: 1.5em;
    font-weight: bold;
    font-family: Alegreya Sans Black;

}

.screen-info {
    position: absolute;
    right: 0;
    margin-right: 20px;
    font-size: 2em;
    display: flex;
}

.screen-info svg {
    color: white;
    height: 50px;
    right: 30px;
    position: relative;
}
.screen-info svg:hover {
    transform: scale(1.025);
}

.screen-info svg:active {
    transform: scale(1);
}

.save-icon-admin svg {
    color: white;
    height: 50px;
    right: 30px;
    position: relative;
    cursor: pointer;
}
.save-icon-admin svg:hover {
    transform: scale(1.025);
}

.save-icon-admin svg:active {
    transform: scale(1);
}
.screen-section {
    margin: 0px 20px 20px 20px;
    background: #F6F9FC;
    box-shadow: 1px 2px 3px 1px #00000016;
    overflow: inherit;
}

.screen {
    position: relative;
    z-index: 1;
    overflow: auto;
    margin-top: -20px;
    height: calc(100vh - 90px);
}

/** Elite Athlete Profile Styling **/

/** General Container **/

.elite-athlete-container-wrapper {
    width: calc(100% - 40px);
    margin: 0 20px 0 20px;
    position: relative;
    align-items: center;
}
.elite-athlete-general-container {
    display: flex;
    background: #F6F9FC;
    float: left;
    height: calc(100% - 50px);
    max-height: 800px;
}

/** Left Hand Component **/
.elite-athlete-left-container {
    width: calc(35% - 30px);
    margin: 0px 10px 0px 20px;
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 295px;
}

@media (min-height: 0px) and (max-height: 760px) {
    .elite-athlete-left-container {
        overflow-y: scroll;
    }
}

/** Right Hand Component **/
.elite-athlete-right-container {
    width: calc(65% - 30px);
    padding-top: 50px;
    margin: 0px 20px 0px 10px;
    height: calc(100% - 50px);
    display: flex;
    overflow: visible;
    /*background-image: linear-gradient(to right , #F6F9FC, #CEE2FF);*/
    min-width: 500px;
    padding-right: 10px;
}

.elite-athlete-right-container .graident-1 {
    position: absolute;
    background: #ecf5fd;
    height: 100%;
    width: 35%;
    top: 0;
}

.elite-athlete-right-container .graident-2 {
    position: absolute;
    background: #e3effd;
    height: 100%;
    width: 30%;
    top: 0;
    left: 35%;
}

.elite-athlete-right-container .graident-3 {
    position: absolute;
    background: #d9e8fd;
    height: 100%;
    width: 20%;
    top: 0;
    left: 65%;
}

.elite-athlete-right-container .graident-4 {
    position: absolute;
    background: #cfe2fe;
    height: 100%;
    width: 15%;
    top: 0;
    left: 85%;
}

/** Selected Image Container **/
.elite-athlete-image-container {
    position: relative;
    max-width: 140px;
    padding: 10px;
    background-color: white;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Dubai Regular;
    box-shadow: 0px 0px 5px 3px lightgrey;
}

@media (min-height: 630px) {
    .elite-athlete-image-container {
        max-width: 180px;
    }
}
/** Elite Athlete Sports Heading Left Container (which houses the span) **/
.elite-athlete-sports-heading-left-container {
    width: 100%;
}

/** Sports Heading (left hand side) **/
.elite-athlete-sports-heading-left {
    font-size: 22px;
    font-weight: 300;
}

/** Sport Selection Container (on left hand side of Elite Athletes Sports page) **/
.sport-selection-container {
    margin-top: 15px;
    width: 90%;
    height: calc(100% - 376px);
    max-width: 370px;
    min-height: 100px;
}

/** For every odd child in the Sport Selection List **/
.sport-selection-container option:nth-child(odd) {
    background-color: #F6F9FC;
}

@media (min-width: 0px) and (max-width: 900px) {
    .elite-athlete-general-container .sport-selection-container option {
        font-size: 12px;
    }
}

.sport-selection-container option {
    height: 24px;
    font-size: 14px;
    font-weight: 300;
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.sport-selection-container option.selected {
    background-color: rgb(180, 180, 180);
}

/** Background color of selected options in Sport Selection List **/
.sport-selection-container option:checked  {
    background: linear-gradient(#C1C1C1, #C1C1C1);
}

/** The container which contains all the individual sports of the elite athlete **/
.elite-profile-sport-container {
    position: relative;
    width: 100%;
    float: left;
    height: max-content;
    margin-bottom: 20px;
    background-image: linear-gradient(to right , #F6F9FC, #CEE2FF);
}

/** Elite Sport Container individual (an individual sport container) **/
.elite-profile-sport-container-individual {
    height: 40px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
}

/** Graph for each individual workout for the sport selection **/
.elite-profile-sport-graph {
    background: linear-gradient(to left, rgb(154, 98, 179, 1), rgb(154, 98, 179, 0.1));
    height: 23px;
    width: calc(60% - 90px);
    float: left;
    position: relative;
}

.loggedin-profile-sport-graph {
    background: linear-gradient(to left, rgb(80, 179, 131, 1), rgb(80, 179, 131, 0.1));
    height: 20px;
    width: calc(60% - 90px);
    float: left;
    left: 0;
    margin-right: 100%;
    position: relative;
}

.elite-profile-sport-graph-bubble,
.loggedin-profile-sport-graph-bubble {
    width: 70px;
    height: 15px;
    position: absolute;
    right: -60px;
    top: 2.5px;
    padding-top: 3px;
    background-color: white;
    font-size: 10px;
    font-weight: bold;
    background: linear-gradient(135deg, transparent 15px, #ffffff 0) top left,linear-gradient(45deg, transparent 15px, #ffffff 0) bottom left;
    background-size: 80% 50%;
    background-repeat: no-repeat;
}

.elite-profile-sport-graph-bubble {
    color: #9a62b3;
}

.loggedin-profile-sport-graph-bubble {
    color: #50b383;
}

.individual-bar-graph-container {
    width: calc(100% - 110px);
}

.individual-bar-graph-container div {
    max-width: 90%;
    transition: width 1s;
}

/** The box container on the far right side of the right side container **/
.elite-profile-sport-right-side-box-container {
    width: 120px;
}

.elite-profile-sport-box {
    background-color: white;
    height: 40px;
    width: 100px;
    align-items: center;
    justify-content: center;
    display: flex;
    box-shadow: 2px 2px 5px #00000016;
    box-sizing: border-box;
    padding: 10px;
}

/** The text within each far right box **/
.elite-profile-sport-box span {
    font-size: 10px;
}

/** The individual boxes within the far right side contianer **/
.elite-profile-sport-right-side-box {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 15px;
}

.elite-profile-sport-box{
    /*font-weight: bold;*/
}

.elite-athlete-score-box {
    color: #9a62b3;
}

.user-score-box {
    color: #50b383;
}

.test-date-calendar-icon {
    height: 50px;
    margin-right: 20px;
}

.test-date-picker-container .react-datepicker-wrapper {
    position: absolute;
}

.test-date-picker-container .react-datepicker-wrapper input {
    width: 40px;
    height: 50px;
    opacity: 0;
    cursor: pointer;
}

.test-date-picker-container .react-datepicker-wrapper .react-datepicker-ignore-onclickoutside {
    width: 40px;
    height: 50px;
    opacity: 0;
    cursor: pointer;
}

.test-date-picker-container .react-datepicker-popper {
    top: -3px !important;
    left: -185px !important;
    z-index: 30 !important;
    opacity: .95;
}

.test-date-picker-container .react-datepicker__triangle {
    left: 160px !important;
}

.elite-athlete-profile-information-popup,
.results-overview-information-popup,
.teachers-resources-information-popup {
    position: absolute;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 30px;
    top: 70px;
    right: 0px;
    width: 500px;
    transition: opacity .3s linear;
    border: 2px solid #d4d4d4;
    border-radius: 3px;
    z-index: 1000;
}

.teachers-resources-information-popup {
    position: absolute;
    display: flex;
    pointer-events: initial;
    flex-direction: column;
    background-color: white;
    padding: 30px;
    right: 0px;
    z-index: 30000;
    width: 500px;
    transition: opacity .3s linear;
    border: 2px solid #d4d4d4;
    border-radius: 3px;
    height: calc(100vh - 180px);
    top: 70px;
    max-height: 700px;
    overflow-y: scroll;
    cursor: default;
}

.teachers-resources-information-popup {
    width: 800px;
}

@media (min-width: 0px) and (max-width: 910px) {
    .teachers-resources-information-popup {
        width: 80vw;
    }
}

.elite-athlete-profile-information-popup::after,
.results-overview-information-popup::after,
.teachers-resources-information-popup::after {
    position: absolute;
    width: 1px;
    height: 20px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid white;
    right: 15px;
    top: -35px;
    content: '';
}

.elite-athlete-profile-information-popup span,
.results-overview-information-popup span,
.teachers-resources-information-popup span {
    color: black;
    font-family: Dubai Regular;
    font-size: 18px;
    text-align: left;
}

.elite-athlete-profile-information-popup .graph-legend {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 5px;
}

.elite-athlete-profile-information-popup .descriptive-text,
.elite-athlete-profile-information-popup .graph-legend,
.results-overview-information-popup .descriptive-text {
    font-size: 15px;
}

.elite-athlete-profile-information-popup span,
.results-overview-information-popup span {
    font-size: 16px;
}

.teachers-resources-information-popup span {
    font-size: 12px;

}
.teachers-resources-information-popup ul {
    color: black;
    font-size: 12px;
    font-family: Dubai Regular;
    text-align: left;
}
.teachers-resources-information-popup table {
    color: black;
    font-size: 11px;
    font-family: Dubai Regular;
    text-align: left;
    border-collapse: collapse;
}

.teachers-resources-information-popup table,
.teachers-resources-information-popup th,
.teachers-resources-information-popup tr {
    border: 1px solid black;
}

.teachers-resources-information-popup table tr:first-child th {
    text-align: center;
}
.square {
    width: 3px;
    height: 3px;
    border: 1px solid black;
    margin-right: 2px;
    margin-left: 2px;
}

.elite-athlete-profile-information-popup .elite-athlete-legend-bar {
    width: 50px;
    height: 17px;
    /*background-color: #9a62b3;*/
}

.elite-athlete-profile-information-popup .me-legend-bar {
    width: 50px;
    height: 17px;
    /*background-color: #50b383;*/
}

/*individual containers for smaller screen*/
@media (min-width: 769px) and (max-width: 1400px) {
    .elite-profile-sport-container-individual {
        height: 26px;
    }
    .elite-profile-sport-box {
        height: 26px;
        padding: 5px;
    }
    .elite-profile-sport-box span {
        font-size: 9px;
    }
    .elite-profile-sport-graph,
    .loggedin-profile-sport-graph {
        height: 0.6em;
    }

    .elite-profile-sport-graph-bubble,
    .loggedin-profile-sport-graph-bubble {
        height: 13px;
        right: -58px;
        top: -4px;
        padding-top: 3px;
        font-size: 9px;
    }
}


/** Date picker styling **/
#root .test-date-picker-container .react-datepicker__header {
    background-color: transparent;
    border-bottom: none;
}

#root .test-date-picker-container .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    display: none;
}

#root .test-date-picker-container .react-datepicker__day {
    font-family: Dubai Regular;
    width: 2rem;
}

#root .test-date-picker-container .react-datepicker__day-name {
    font-family: Dubai Regular;
    width: 2rem;
}

#root .test-date-picker-container .react-datepicker__day.--weekend{
    color: grey;
}

#root .test-date-picker-container .react-datepicker__day-name:nth-child(6), #root .test-date-picker-container .react-datepicker__day-name:nth-child(7) {
    color: grey;
}

#root .test-date-picker-container .react-datepicker__day--outside-month {
    opacity: .5;
}

#root .test-date-picker-container .react-datepicker__week .react-datepicker__day--selected {
    background-color: #b6b6b6;
    color: black;
    font-weight: bold;
}

#root .test-date-picker-container .react-datepicker__month-select {
    font-family: Dubai Regular;
}

#root .test-date-picker-container .react-datepicker__year-select {
    font-family: Dubai Regular;
}

#root .test-date-picker-container .react-datepicker {
    background-color: #ffffff9f;
    border-radius: 20px;
}

#root .test-date-picker-container .react-datepicker__navigation--previous {
    left: 25px;
}

#root .test-date-picker-container .react-datepicker__navigation--next {
    right: 35px;
}

#root .test-date-picker-container .react-datepicker__navigation--previous::before {
    content: "\f053";
    color: black;
    left: 0;
    position: absolute;
    text-align: center;
    font-size: 21px;
    font-family: FontAwesome;
}

#root .test-date-picker-container .react-datepicker__navigation--next::before {
    content: "\f054";
    color: black;
    left: 0;
    position: absolute;
    text-align: center;
    font-size: 21px;
    font-family: FontAwesome;
}

#root .test-date-picker-container .react-datepicker__navigation {
    border: none;
    text-indent: 0;
    color: transparent;
    overflow: initial;
}

#root .test-date-picker-container .react-datepicker__month {
    margin-left: 20px;
    margin-right: 20px;
}

#root .test-date-picker-container .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    transform: translate(45px, 0px);
}

#root .test-date-picker-container .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: 0px;
}

.leave-access-user-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    height: 26px;
    margin: 1% 2%;
    border: none;
    border-radius: 20px;
    box-shadow: 0px 2px 5px #444444;
    background: white;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    text-align: center;
}

.sportMatchButton {
    margin-top: 10px;
    background: #9a62b3;
    border: 0;
    padding: 6px 15px;
    border-radius: 20px;
    box-shadow: 1px 2px 3px 1px #00000016;
    cursor: pointer;
    font-family: Dubai Regular;
    font-size: 16px;
    margin-left: 25px;
    margin-right: 25px;
    color: white;
}

.loading-indicator {
    margin-left: 10px;
}

.loading-indicator svg {
    height: initial;
    width: initial;
    right: initial;
    color: inherit;
}